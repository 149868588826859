import create from "zustand";
import { persist } from "zustand/middleware";
import { uploadFormSlice } from "./slices/uploadForm";
import { userSlice } from "./slices/user";
import { customerSlice } from "./slices/customer";
import { creatorSlice } from "./slices/creator";

export const useBoundStore = create(
  persist(
    (...a) => ({
      ...userSlice(...a),
      ...uploadFormSlice(...a),
      ...customerSlice(...a),
      ...creatorSlice(...a),
      releaseDate: JSON.stringify(a.releaseDate),
      file: JSON.stringify(a.file),
    }),
    {
      name: "bound-store",
    }
  )
);
