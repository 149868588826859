import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "./Button";

function Unauthorized({ creator = null, username }) {
  const history = useHistory();

  const viewCreator = () => {
    if (username) history.push(`/creator/${username}`);
  };

  return (
    <div className="flex-container">
      <div className="large-text">{`You are not authorized to access ${
        creator || "this creator"
      }'s content`}</div>
      <div className="standard-text">{`You can view their profile and subscribe below`}</div>
      <Button title={"View Creator"} onClick={viewCreator} />
    </div>
  );
}

export default Unauthorized;
