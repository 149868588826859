import React, { useState } from "react";

function Avatar({
  height = 50,
  url = "https://bulma.io/images/placeholders/256x256.png",
  promptText = null,
  showPromptByDefault = false,
}) {
  const [hovering, setHovering] = useState(false);

  return (
    <div
      className="avatar-container "
      style={{ height, width: height }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <img
        style={{
          maxHeight: height,
          minHeight: height,
          width: height,
          borderRadius: height / 2,
        }}
        alt="avatar"
        src={url || "https://bulma.io/images/placeholders/256x256.png"}
      />
      {promptText && (hovering || showPromptByDefault) && (
        <div
          className="circular-prompt"
          style={{
            height: height / 2,
            width: height,
            borderBottomLeftRadius: height / 2,
            borderBottomRightRadius: height / 2,
          }}
        >
          <div className="circular-prompt-top-section"></div>
          <div className="circular-prompt-main">
            <div
              className="circular-prompt-text"
              style={{
                fontSize: height * 0.05,
              }}
            >
              {promptText}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Avatar;
