import React from "react";
import useWindowDimensions from "../hooks/WindowDimensions";
import "pure-react-carousel/dist/react-carousel.es.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Card from "./Card";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";

const calcSlides = (width) => {
  if (!width) return 1;
  if (width > 1200) return 3;
  if (width > 750) return 2;
  return 1;
};

function CardCarousel({
  title = "Popular Yoga Instructors",
  users = [],
  onSelectCard,
}) {
  const { height, width } = useWindowDimensions();

  let visibleSlides = calcSlides(width);
  const step = Math.min(visibleSlides, 2);

  return (
    <div className="carousel-container">
      <div className="large-text carousel-title">{title}</div>
      <CarouselProvider
        visibleSlides={visibleSlides}
        totalSlides={users.length}
        step={step}
        naturalSlideWidth={400}
        naturalSlideHeight={600}
      >
        <div style={{ position: "relative" }}>
          <Slider style={{ width: "100%" }}>
            {users.map((u, i) => (
              <Slide key={i} index={i}>
                <Card
                  title={`${u.firstName || ""} ${u.lastName || ""}`}
                  smallUri={u.avatar}
                  mainUri={u.avatar}
                  desc={u.headline}
                  onClick={() => onSelectCard(u)}
                  tag={u?.mainGenre}
                />
              </Slide>
            ))}
          </Slider>
          <ButtonBack className="carousel-button carousel-left">
            <FiChevronLeft style={{ fontSize: 34 }} />
          </ButtonBack>
          <ButtonNext className="carousel-button carousel-right">
            <FiChevronRight style={{ fontSize: 34 }} />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  );
}

export default CardCarousel;
