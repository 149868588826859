import React from "react";
import BoxesLoader from "./BoxesLoader";

function PageLoader() {
  return (
    <div className="centered-flex-container">
      <BoxesLoader />
    </div>
  );
}

export default PageLoader;
