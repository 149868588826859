import axios from "axios";
import { Api } from "./client";

// const { AUTH0_AUDIENCE, } = process.env

export const getUploadUrl = async (size) => {
  try {
    const res = await Api.post("/videos/uploadLink", {
      uploadLength: size,
    });

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

export const uploadShortVidToCF = async (url, video) => {
  try {
    /** Check if using tus protocol */

    const formData = new FormData();
    formData.append("file", video);

    const res = await axios({
      method: "post",
      url: url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

export const getVidStatus = async (id) => {
  try {
    const res = await Api.get(`/videos/status/${id}`);

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

export const submitDraftVideo = async (video) => {
  try {
    const res = await Api.post(`/videos`, { ...video });

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

export const setVideoThumbnail = async (id, ts, dur) => {
  try {
    const res = await Api.put(`/videos/thumbnail`, { id, ts, dur });

    return res && res.data;
  } catch (err) {
    console.error("ERR---", err);
  }
};

/** API allows username to be passed instead of email */
export const getUserVids = async (emailOrUsername) => {
  try {
    const res = await Api.get(`/videos/all/${emailOrUsername}`);

    return res && res.data;
  } catch (err) {
    console.error("ERR GETTING USER'S VIDS", err);
    throw {
      code: err?.response?.status || 500,
    };
  }
};

export const getCreatorPreview = async (emailOrUsername) => {
  try {
    const res = await Api.get(`/videos/preview/${emailOrUsername}`);

    return res && res.data;
  } catch (err) {
    console.error("ERR GETTING CREATOR's PREVIEW", err);
    throw {
      code: err?.response?.status || 500,
    };
  }
};

export const syncVid = async (id) => {
  try {
    const res = await Api.put(`/videos/sync`, { id });

    return res && res.data;
  } catch (err) {
    console.error("ERR SYNCING VID", err);
  }
};

export const getCreatorInfo = async (username) => {
  try {
    const res = await Api.get(`/users/creator/${username}`);

    console.log("RES---", res);

    return res && res.data;
  } catch (err) {
    console.error("ERR GETTING USER INFO", err);
  }
};

export const getUserInfo = async (email) => {
  try {
    const res = await Api.get(`/users/${email}`);

    console.log("RES---", res);

    return res && res.data;
  } catch (err) {
    console.error("ERR GETTING USER INFO", err);
  }
};

export const updateUserInfo = async (newUserFields) => {
  try {
    const res = await Api.put(`/users`, { ...newUserFields });

    return res && res.data;
  } catch (err) {
    console.error("ERR GETTING USER INFORMATION", err);
  }
};

export const uploadAvatar = async (img, email) => {
  try {
    const formData = new FormData();
    formData.append("img", img);
    formData.append("email", email);

    console.log("about to upload avatar", img);

    const res = await Api.put(`/users/avatar`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

export const getFeaturedCreators = async (cat = "all", search = null) => {
  try {
    const url = search
      ? `/users/featured/all${search}`
      : `/users/featured/${cat}`;
    const res = await Api.get(`/users/featured/all${search}`);

    return res && res.data;
  } catch (err) {
    console.error("ERR GETTING FEATURED CREATORS", err);
  }
};

export const createStripeAcct = async () => {
  try {
    const res = await Api.post("/users/setUp/payAcct");

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

export const putPricing = async (prices) => {
  try {
    const res = await Api.put("/creators/payments/pricing", { prices });

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

export const postCheckoutSession = async (priceId, username) => {
  try {
    const res = await Api.post("/users/setUp/checkoutSession", {
      priceId,
      username,
    });

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

export const getSubscriptions = async () => {
  try {
    const res = await Api.get("/users/subs/all");

    return res && res.data;
  } catch (err) {
    console.error("ERR", err);
  }
};

/** API allows username to be passed instead of email */
export const getOwnVids = async () => {
  try {
    const res = await Api.get(`/videos/internal/all`);

    return res && res.data;
  } catch (err) {
    console.error("ERR GETTING CREATOR'S OWN VIDS", err);
    throw {
      code: err?.response?.status || 500,
    };
  }
};
