import React, { useEffect, useState, useRef } from "react";
import Dropzone from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import throttle from "lodash/throttle";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getUploadUrl,
  uploadShortVidToCF,
  getVidStatus,
  submitDraftVideo,
  setVideoThumbnail,
} from "../utility";
import UploadForm from "./UploadForm";
import Button from "./Button";
import { useBoundStore } from "../state/store";
import { spaceBetweenThumbnails } from "../utility/rules";
import {
  generateThumbnailUrl,
  getThumbnailUrlFromIndex,
} from "../utility/videoHelpers";

const {
  REACT_APP_ENV: env,
  REACT_APP_API_STAGE,
  REACT_APP_API_LOCAL,
} = process.env;
const baseURL = env === "local" ? REACT_APP_API_LOCAL : REACT_APP_API_STAGE;

let pollingStatus = null;

function UploadModal({
  active = false,
  close,
  tusUpload,
  resume,
  editing = false,
  submitCB,
}) {
  /** Zustand state */
  const desc = useBoundStore((state) => state.desc);
  const title = useBoundStore((state) => state.title);
  const vidId = useBoundStore((state) => state.vidId);
  const vidMetadata = useBoundStore((state) => state.vidMetadata);
  const dur = useBoundStore((state) => state.dur);
  const thumbnailIndex = useBoundStore((state) => state.thumbnailIndex);
  const releaseOpt = useBoundStore((state) => state.releaseOpt);
  const releaseDate = useBoundStore((state) => state.releaseDate);
  const pctComplete = useBoundStore((state) => state.pctComplete);
  const vidUploadStatus = useBoundStore((state) => state.vidUploadStatus);
  const tusUploadStatus = useBoundStore((state) => state.tusUploadStatus);
  const file = useBoundStore((state) => state.file);
  const formMode = useBoundStore((state) => state.formMode);

  /** Zustand actions */
  const setVidId = useBoundStore((state) => state.setVidId);
  const setVidInProgressId = useBoundStore((state) => state.setVidInProgressId);
  const setVidMetadata = useBoundStore((state) => state.setVidMetadata);
  const setPctComplete = useBoundStore((state) => state.setPctComplete);
  const setVidUploadStatus = useBoundStore((state) => state.setVidUploadStatus);
  const setTusUploadStatus = useBoundStore((state) => state.setTusUploadStatus);
  const setFormMode = useBoundStore((state) => state.setFormMode);
  const setFile = useBoundStore((state) => state.setFile);
  const resetForm = useBoundStore((state) => state.resetForm);
  const setVidDur = useBoundStore((state) => state.setVidDur);
  const editVid = useBoundStore((state) => state.editVid);

  /** URL for creator-driven upload */
  const [urlLoading, updateUrlLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [nextUrl, updateNextUrl] = useState(null);

  /** Upload states */
  const [vidUploading, updateVidUploading] = useState(false);
  const [uploadRes, updateUploadResult] = useState(false);

  /** Current Video Processing States (after initial upload) */
  const [uploadStatus, updateStatus] = useState(null);
  const pctRef = useRef(pctComplete);
  pctRef.current = pctComplete;

  /** Needed for auth header on TUS upload */
  const { getAccessTokenSilently: getToken } = useAuth0();

  const submit = async () => {
    try {
      /** First do some input validation */
      //Input Validation Placeholder

      /** If release option is set to schedule, but a date has not been selected, do not submit */

      if (releaseOpt === "schedule" && !releaseDate) {
        alert("Please schedule a release date before saving!");
        return;
      }

      setLoading(true);
      let uState = "inProgress";

      if (!vidUploading || pctComplete === 100) {
        uState = "uploaded";
      }

      /** Structure and send request */
      const video = {
        desc,
        title,
        cfId: vidId,
        height: vidMetadata?.height,
        width: vidMetadata?.width,
        releaseOpt,
        releaseDate,
        uState,
      };

      /** Side effects of request */
      // setProgressState("submitted_loading");
      const res = await submitDraftVideo(video);
      if (submitCB) submitCB(video);
      if (editing) resetForm();

      if (vidId) {
        const ts = thumbnailIndex * spaceBetweenThumbnails;
        /** TODO: Pull duration into Zustand store */
        const newVid = await setVideoThumbnail(vidId, ts, dur);
        const newThumbnail = getThumbnailUrlFromIndex(vidId, thumbnailIndex);
        newVid.thumb = newThumbnail;

        editVid(newVid);
      }

      if (res && res.id) {
        /** TODO: Handle Success Animation HERE */
      }

      setLoading(false);
      setSuccess(Date.now());
    } catch (err) {
      /** TODO: Handle Error States HERE */
      // console.log("ERR---", err);
    }
  };

  const parseCFProcessingStatus = (data) => {
    const { result: video } = data;

    /** Check if video has not uploaded yet */
    if (!video) return 0;

    if (video && !vidMetadata) {
      setVidMetadata(video?.input);
    }

    if (video?.status?.state !== "ready") {
      return video?.status?.pctComplete || 50;
    }

    if (video.duration) setVidDur(video.duration);

    return 100;
  };

  const getProcessingStatus = async (pctRef, providedId = null) => {
    let id = providedId || vidId;
    if (!id) return;

    const data = await getVidStatus(id);

    if (!data || !id) return stopPollingVideoStatus();

    const latestPct = Math.round(parseCFProcessingStatus(data));

    if (latestPct === 100 || (data.success && data.readyToStream)) {
      stopPollingVideoStatus();
      setPctComplete(100);
      // setProgressState("complete");
      setVidUploadStatus("done");
      return;
    }

    const addPercentage = Math.round(Math.random() * 10) % 5;

    const progBar = Math.max(latestPct, pctRef + addPercentage);

    const nextPct = Math.min(progBar, 80);
    if (nextPct > pctRef) setPctComplete(nextPct);

    return nextPct;
  };

  const pollVideoStatus = (providedId = null) => {
    if (pollingStatus) clearInterval(pollingStatus);

    pollingStatus = setInterval(() => {
      const pct = pctRef.current;
      getProcessingStatus(pct, providedId);
    }, 5000);
  };

  const stopPollingVideoStatus = () => {
    if (pollingStatus) clearInterval(pollingStatus);

    pollingStatus = null;
  };

  const refreshUploadUrl = async (size) => {
    try {
      if (urlLoading) return;
      updateUrlLoading(true);

      const { url: vidUrl, id: vidId } = await getUploadUrl(size);

      updateNextUrl(vidUrl);
      updateUrlLoading(false);
      setVidId(vidId);

      return { id: vidId, url: vidUrl };
    } catch (err) {
      console.error(err);
    }
  };

  const initializeVid = async () => {
    if (!vidId) return;
    const video = {
      desc: "",
      title: "Untitled",
      cfId: vidId,
      height: vidMetadata?.height,
      width: vidMetadata?.width,
      releaseOpt,
      uState: "inProgress",
    };

    /** Side effects of request */
    // setProgressState("submitted_loading");

    const res = await submitDraftVideo(video);
  };

  const throttledInitVid = throttle(initializeVid, 5000);

  useEffect(() => {
    if (active && vidId && !editing) initializeVid();
  }, [vidId]);

  useEffect(() => {
    if (active && tusUploadStatus === "uploading") {
      if (file && file.size && !editing) {
        resume(file, `${baseURL}/videos/tusUpload/${file?.size}`, updateState);
      }
    }
  }, [active]);

  useEffect(() => {
    if (tusUploadStatus === "done") pollVideoStatus();
    else stopPollingVideoStatus();
  }, [tusUploadStatus]);

  useEffect(() => {
    if (vidUploadStatus === "done") stopPollingVideoStatus();
  }, [vidUploadStatus]);

  if (!active) return null;

  const updateState = (event, value) => {
    if (event === "progress") setPctComplete(value);
    if (event === "vidStatus") setVidUploadStatus(value);
    if (event === "vidId") {
      setVidId(value);
      setVidInProgressId(value);
    }
    if (event === "tusUploadStatus") setTusUploadStatus(value);
    if (event === "resetForm") resetForm();
  };

  const onDrop = async (files) => {
    try {
      updateVidUploading(true);
      let id = vidId;
      let url = nextUrl;
      const file = files && files[0];

      if (file && file.size > 200000000) {
        if (vidUploadStatus === "uploading") {
          alert(`Video currently uploading, ${pctComplete}% complete`);
          return;
        }
        setFormMode("uploadInProgress");

        const newFileObj = {
          path: file.path,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          webkitRelativePath: file.webkitRelativePath,
          name: file.name,
          size: file.size,
          type: file.type,
          data: {
            size: file.size,
            lastModified: file.lastModified,
          },
        };

        setFile(newFileObj);
        const token = await getToken();
        return tusUpload(
          files[0],
          `${baseURL}/videos/tusUpload/${files[0].size}`,
          updateState,
          token
        );
      } else {
        const { id: newVidId, url: newVidUrl } = await refreshUploadUrl(
          files[0].size
        );

        updateVidUploading(true);
        setVidId(newVidId);
        pollVideoStatus(newVidId);

        uploadShortVidToCF(newVidUrl, files[0]);
        setFormMode("uploadInProgress");

        /** Create draft video in our system with the cf id */

        const video = {
          title: "Untitled",
          cfId: id,
          height: vidMetadata?.height || 0,
          width: vidMetadata?.width || 0,
          releaseOpt: "inProgress",
        };

        if (id) {
          const res = await submitDraftVideo(video);
        }
      }
    } catch (err) {
      console.error("ERROR", err);
    }
  };

  const submitDisabled = !desc || !title;

  const uploadContent = (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <div className="drop-container" {...getRootProps()}>
          <div className="drop-cta"></div>
          <input {...getInputProps()} />
          <div className="drop-icon-circle">
            <FiUpload className="drop-icon" size={60} />
          </div>
          <div className="large-text">Upload Workout!</div>
          <i>Drag and drop video file or click icon</i>
          <div />
        </div>
      )}
    </Dropzone>
  );

  const successUploadContent = (
    <UploadForm
      vidId={vidId}
      readyToStream={Boolean(pctComplete && pctComplete === 100)}
      pctComplete={pctComplete}
      dur={dur}
    />
  );

  const editContent = (
    <UploadForm
      vidId={vidId}
      readyToStream={Boolean(pctComplete && pctComplete === 100)}
      pctComplete={pctComplete}
      dur={dur}
      editing={true}
    />
  );

  const calcStage = () => {
    if (editing) return editContent;
    if (formMode === "uploadInProgress") return successUploadContent;
    if (formMode === "upload") return uploadContent;
    else return uploadContent;
  };

  const closeModal = () => {
    /** reset state back to defaults */
    close();
    if (tusUploadStatus !== "uploading") {
      resetForm();
      return;
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card extra-wide-modal">
        <header className="modal-card-head">
          <p className="modal-card-title">{`${
            editing ? "Edit" : "Add"
          } Workout`}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={closeModal}
          ></button>
        </header>
        <section className="modal-card-body upload-main-container">
          {calcStage()}
        </section>
        <footer className="modal-card-foot">
          <Button
            title={"Save"}
            onClick={submit}
            disabled={submitDisabled}
            color={"var(--cta-color)"}
            loading={loading}
            success={success}
          />
          <Button
            title={"Cancel"}
            onClick={closeModal}
            textColor={"black"}
            strong={false}
            primary={false}
          />
        </footer>
      </div>
    </div>
  );
}

export default UploadModal;
