import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiVideo, FiSearch } from "react-icons/fi";
import { useBoundStore } from "../state/store";
import Avatar from "./Avatar";
import Button from "./Button";
import UploadModal from "./UploadModal";
import SearchInput from "./SearchInput";

function NavBar({ tusUpload, resume }) {
  const { isAuthenticated, loginWithRedirect, user, isLoading, logout } =
    useAuth0();

  const [hamburgerActive, toggleHamburger] = useState(false);

  const avatar = useBoundStore((state) => state.avatar);
  const userType = useBoundStore((state) => state.userType);

  const handleSignIn = (signUp = true) => {
    loginWithRedirect({ screen_hint: signUp && "signup" });
  };

  const handleLogOut = () => {
    logout({ returnTo: "https://www.techtonic.fit/market" });
  };

  // const [modalOpen, toggleModal] = useState(false);
  const modalOpen = useBoundStore((state) => state.formOpen);
  const toggleModal = useBoundStore((state) => state.updateFormOpen);
  const tusUploadStatus = useBoundStore((state) => state.tusUploadStatus);
  const pctComplete = useBoundStore((state) => state.pctComplete);

  const uploading = tusUploadStatus === "uploading";

  const defaultNav = (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="navbar-item">
          <Link className="navbar-item" to="/market">
            <img
              src="/TechTonic_Logo_Main.jpg"
              style={{ objectFit: "contain" }}
              width="150"
              height="28"
            />
          </Link>
          <span
            className="tag bottom-right"
            data-type={"beta"}
            style={{ backgroundColor: "#EF0075", color: "white" }}
          >
            {"Beta"}
          </span>
        </div>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <Link className="navbar-item" to="/market">
            Home
          </Link>
        </div>
      </div>
    </nav>
  );

  const newUserNav = (
    <nav
      id="newUserNav"
      className="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <div className="navbar-item">
          <Link className="navbar-item" to="/market">
            <img
              src="/TechTonic_Logo_Main.jpg"
              style={{ objectFit: "contain" }}
              width="150"
              height="28"
            />
          </Link>
          <span
            className="tag bottom-right"
            data-type={"beta"}
            style={{ backgroundColor: "#EF0075", color: "white" }}
          >
            {"Beta"}
          </span>
        </div>
        <a
          role="button"
          className={`navbar-burger ${hamburgerActive && "is-active"}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="newUserNav"
          onClick={() => toggleHamburger(!hamburgerActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbarBasicExample"
        className={`navbar-menu ${hamburgerActive && "is-active"}`}
      >
        <div className="navbar-start">
          <div
            className="navbar-item"
            style={{
              marginTop: 4,
              marginBottom: 4,
              padding: 0,
              borderColor: "black",
              borderWidth: 1,
              borderRadius: 9999,
              border: "1px solid black",
              maxWidth: 300,
            }}
          >
            <SearchInput />
            {/* <div className="control">
            <a className="button is-info">Search</a>
          </div> */}
          </div>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Button
                title="Log in"
                color="#685efc"
                onClick={() => handleSignIn(false)}
              />
              <Button
                title="Sign Up"
                color="black"
                onClick={() => handleSignIn()}
              />
            </div>
          </div>
        </div>
      </div>
      <UploadModal
        active={modalOpen}
        close={() => toggleModal(false)}
        tusUpload={tusUpload}
        resume={resume}
      />
    </nav>
  );

  if (isLoading) return defaultNav;

  if (!isAuthenticated) return newUserNav;

  const isCreator = userType && userType === "creator";

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="navbar-item">
          <Link className="navbar-item" to="/market">
            <img
              src="/TechTonic_Logo_Main.jpg"
              style={{ objectFit: "contain" }}
              width="150"
              height="28"
            />
          </Link>
          <span
            className="tag bottom-right"
            data-type={"beta"}
            style={{ backgroundColor: "#EF0075", color: "white" }}
          >
            {"Beta"}
          </span>
        </div>
        <a
          role="button"
          className={`navbar-burger ${hamburgerActive && "is-active"}`}
          aria-label="menu"
          aria-expanded="true"
          data-target="navbarBasicExample"
          onClick={() => toggleHamburger(!hamburgerActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${hamburgerActive && "is-active"}`}
      >
        <div className="navbar-start">
          {isCreator && (
            <Link className="navbar-item" to="/dashboard">
              My Videos
            </Link>
          )}

          <Link className="navbar-item" to="/subscriptions">
            Subscriptions
          </Link>

          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">More</a>

            <div className="navbar-dropdown">
              <a className="navbar-item">About</a>
              <a className="navbar-item">Contact</a>
              <hr className="navbar-divider" />
              <a className="navbar-item">Give feedback</a>
            </div>
          </div>
          <div
            className="navbar-item"
            style={{
              marginTop: 4,
              marginBottom: 4,
              padding: 0,
              borderColor: "black",
              borderWidth: 1,
              borderRadius: 9999,
              border: "1px solid black",
            }}
          >
            <SearchInput />
          </div>
        </div>
        <div>
          {uploading && (
            <div className="navbar-item">{`Upload in progress (${pctComplete}%)`}</div>
          )}
          <div className="navbar-item">
            <div className="buttons">
              {isCreator && (
                <Button
                  title={"Create"}
                  icon={<FiVideo className="primary-icon" size={24} />}
                  primary={true}
                  onClick={() => toggleModal(!modalOpen)}
                />
              )}
              <div className="navbar-item has-dropdown is-hoverable">
                <Avatar url={avatar} />
                <div className="navbar-dropdown is-right">
                  <Link to="/profile">
                    <div className="navbar-item">Profile</div>
                  </Link>
                  {!isCreator && (
                    <Link>
                      <div className="navbar-item">Become a Creator</div>
                    </Link>
                  )}
                  {isCreator && (
                    <Link to="/payments">
                      <div className="navbar-item">Payments</div>
                    </Link>
                  )}
                  <div className="navbar-item clickable" onClick={handleLogOut}>
                    Log Out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadModal
        active={modalOpen}
        close={() => toggleModal(false)}
        tusUpload={tusUpload}
        resume={resume}
      />
    </nav>
  );
}

export default NavBar;
