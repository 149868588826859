import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

function SearchInput() {
  const history = useHistory();
  const [searchTerm, updateSearch] = useState(null);

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchTerm) history.push(`/search?s=${searchTerm}`);
    }
  };

  return (
    <div className="field has-addons">
      <div
        className="control"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minWidth: 300,
          padding: 10,
        }}
      >
        <FiSearch size={24} color={"#6B7074"} />
        <input
          className="input no-outline"
          type="text"
          placeholder="Find a creator"
          role="combobox"
          aria-invalid="false"
          autoFocus={false}
          style={{ border: 0, boxShadow: "none" }}
          onKeyDown={handleSearch}
          onChange={(e) => updateSearch(e?.target?.value)}
        ></input>
      </div>
    </div>
  );
}

export default SearchInput;
