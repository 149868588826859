import { BoxesLoader } from "react-awesome-loaders";

import React from "react";

function Loader() {
  return (
    <>
      <BoxesLoader
        boxColor={"#f9cb9c"}
        shadowColor={"rgba(211,211,211,.2)"}
        style={{ marginBottom: "20px" }}
        desktopSize={"128px"}
        mobileSize={"80px"}
      />
    </>
  );
}

export default Loader;
