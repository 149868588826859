import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import React from "react";
import ContentLoader from "react-content-loader";

const convertSizeToDimensions = (sz) => {
  // Starting with small width / height and scaling from there
  let width = 320;
  let height = 180;
  switch (sz) {
    case "small":
      break;
    case "medium":
      width *= 2;
      height *= 2;
      break;
    case "large":
      width *= 3;
      height *= 3;
      break;
    default:
      break;
  }

  return { width, height };
};

const scaleDimensions = (width, height) => {
  const maxHeight = 400;
  const maxWidth = 400;

  let adjWidth = width;
  let adjHeight = height;

  // return { adjWidth: width / 6, adjHeight: height / 6 };

  while (adjWidth > maxWidth || adjHeight > maxHeight) {
    adjWidth = adjWidth * 0.9;
    adjHeight = adjHeight * 0.9;
  }

  return { adjWidth, adjHeight };

  // const maxWidth = 360;
  // const maxHeight = (maxWidth * width) / height;

  // if (width > height) {
  //   if (width > maxWidth) {
  //     const adjWidth = maxWidth;
  //     const adjHeight = (maxWidth / width) * height;
  //     return { adjWidth, adjHeight };
  //   }
  // }

  // if (height > width) {
  //   if (height > maxHeight) {
  //     const adjHeight = maxHeight;
  //     const adjWidth = (maxHeight / height) * width;
  //     return { adjWidth, adjHeight };
  //   }
  // }

  // return { adjWidth: width, adjHeight: height };
};

const convertStatusToText = (pctComplete) => {
  if (!pctComplete)
    return "Uploading... \n\n Fill out the form \n while you wait!";
  if (pctComplete)
    return `Fill out and save the form so your content is ready to go! \n\n We will contact you when the video has completed processing!`;
};

/** Default player is going to be Cloudflare, but we can swap out another provider */

function PlayerContainer({
  vidId,
  readyToStream = false,
  size = "small",
  pctComplete = 0,
  width = 350,
  height = 250,
}) {
  if (!readyToStream) {
    return (
      <div style={{ position: "relative", width, height }}>
        <ContentLoader
          speed={2}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          title={"test"}
        >
          <rect x="0" y="0" width={width} height={height} />
          <text x="10" y="10" className="heavy">
            test
          </text>
        </ContentLoader>
        <div className="text-absolute-center">
          {convertStatusToText(pctComplete)}
        </div>
      </div>
    );
  }

  return (
    <div className="player-container">
      <iframe
        src={`https://customer-i7c3d2jusxqj1azx.cloudflarestream.com/${vidId}/iframe`}
        style={{ border: "none", marginBottom: 8 }}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen={true}
        id="stream-player"
      ></iframe>
    </div>
  );
}

export default React.memo(PlayerContainer);
