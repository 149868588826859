import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getFeaturedCreators } from "../utility";
import SearchCard from "../components/SearchCard";

const categories = [
  {
    name: "yoga",
    displayName: "Yoga",
    tagline: "",
  },
  {
    name: "dance",
    displayName: "Dance Cardio",
    tagline: "",
  },
  {
    name: "barre",
    displayName: "Barre",
    tagline: "",
  },
];

function Market() {
  const [allResults, updateAllResults] = useState([]);
  const history = useHistory();

  const { search } = useLocation();

  console.log("search---", search);

  const onSelectCard = (u) => {
    if (u?.username) history.push(`/creator/${u.username}`);
    else history.push(`/creator/${"john-henry"}`);
  };

  useEffect(() => {
    async function getCreators() {
      const creators = await getFeaturedCreators("all", search);

      updateAllResults(creators);
    }
    getCreators();
  }, [search]);

  console.log("results", allResults);

  return (
    <div className="start-top-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          position: "relative",
        }}
      >
        {allResults.map((r) => (
          <SearchCard
            key={r?.username}
            otherTags={r?.genres}
            mainTag={r?.mainGenre}
            subtitle={r?.headline}
            title={`${r.firstName} ${r.lastName}`}
            mainUri={r?.avatar}
            onClick={() => onSelectCard(r)}
            price={r?.mPrice}
          />
        ))}
      </div>
    </div>
  );
}

export default Market;
