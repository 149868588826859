import axios from "axios";

const {
  REACT_APP_ENV: env,
  REACT_APP_API_STAGE,
  REACT_APP_API_LOCAL,
} = process.env;
const baseURL = env === "local" ? REACT_APP_API_LOCAL : REACT_APP_API_STAGE;

const Api = axios.create({
  baseURL,
  timeout: 60000,
});

const gentleGetToken = async (getToken) => {
  try {
    const token = await getToken();
    return token;
  } catch (err) {
    return "";
  }
};

/**
 * @param getToken
 * getToken comes from Auth0. We moved this into an axios interceptor
 * and out of the utility index file. Every API call will require a JWT
 * for the foreseeable future
 */

export const addAuthHeader = (getToken) => {
  Api.interceptors.request.use(async (config) => {
    console.log(config);
    // if (openUrls.includes(config?.url)) {
    //   return config;
    // }

    const token = await gentleGetToken(getToken);

    const newConfig = config;
    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  });
};

export { Api };
