const initialState = {
  title: "",
  desc: "",
  previewState: null,
  vidId: null,
  dur: null,
  vidInProgressId: null,
  thumbnail: null,
  progressState: null,
  vidMetadata: null,
  releaseOpt: null,
  releaseDate: null,
  vidUploadStatus: null,
  tusUploadStatus: null,
  pctComplete: 0,
  formOpen: false,
  formMode: "upload",
  file: null,
  thumbsTs: 0,
  editModal: false,
};

export const uploadFormSlice = (set) => ({
  ...initialState,
  updateTitle: (title) => set(() => ({ title })),
  updateDesc: (desc) => set(() => ({ desc })),
  updateThumbnail: (thumbnailIndex) => set(() => ({ thumbnailIndex })),
  setVidId: (vidId) => set(() => ({ vidId })),
  setVidInProgressId: (vidInProgressId) => set(() => ({ vidInProgressId })),
  setVidMetadata: (vidMetadata) => set(() => ({ vidMetadata })),
  setPctComplete: (pctComplete) => set(() => ({ pctComplete })),
  resetForm: () => set(() => initialState),
  setProgressState: (newState) => set(() => ({ progressState: newState })),
  setVidUploadStatus: (vidUploadStatus) => set(() => ({ vidUploadStatus })),
  setTusUploadStatus: (tusUploadStatus) => set(() => ({ tusUploadStatus })),
  setFile: (file) => set(() => ({ file })),
  updateReleaseOpt: (releaseOpt) => set(() => ({ releaseOpt })),
  updateThumbTs: (thumbTs) => set(() => ({ thumbTs })),
  updateReleaseDate: (releaseDate) =>
    set(() => {
      return { releaseDate };
    }),
  updateFormOpen: (formOpen) => set(() => ({ formOpen })),
  setFormMode: (formMode) => set(() => ({ formMode })),
  setEditModal: (editModal) => set(() => ({ editModal })),
  setVidDur: (dur) => set(() => ({ dur })),
});
