import React from "react";

function Card({
  title = "Untitled",
  smallUri = "https://bulma.io/images/placeholders/96x96.png",
  mainUri = "https://bulma.io/images/placeholders/1280x960.png",
  desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris. <a>@bulmaio</a>.<a href="#">#css</a>{" "}<a href="#">#responsive</a>',
  onClick,
  tag = "barre",
}) {
  return (
    <div className="card" onClick={onClick}>
      <div className="card-image">
        <figure>
          <div
            src={mainUri}
            alt="Placeholder image"
            style={{
              width: "100%",
              height: 300,
              backgroundImage: `url(${mainUri})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          />
        </figure>
      </div>
      {/* <div
        style={{
          width: "100%",
          minHeight: 200,
          maxHeight: 300,
          backgroundSize: "cover",
          backgroundImage: `url(${mainUri})`,
        }}
      ></div> */}
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-64x64">
              <div
                src={mainUri}
                alt="Placeholder image"
                style={{
                  width: "100%",
                  height: 48,
                  backgroundImage: `url(${mainUri})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              />
            </figure>
          </div>
          <div className="media-content relative">
            <p className="title is-4">{title}</p>
            <span className="tag top-right" data-type={tag}>
              {tag}
            </span>
          </div>
        </div>

        <div className="content">{desc.slice(0, 120)}</div>
      </div>
    </div>
  );
}

export default Card;
