import "cropperjs/dist/cropper.css";
import React, { useEffect, useState, useRef } from "react";
import Dropzone from "react-dropzone";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { FiHelpCircle } from "react-icons/fi";
import Cropper from "react-cropper";
import { useBoundStore } from "../state/store";
import { getUserInfo, uploadAvatar, updateUserInfo } from "../utility";
import { fetchUserInfo } from "../utility/user";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import { FiUpload } from "react-icons/fi";
import Input from "../components/Input";
import PageLoader from "../components/PageLoader";
import SelectDrop from "../components/SelectDrop";
import { mainPicAspectRatio } from "../utility/rules";

const allCategories = [
  {
    value: "yoga",
    name: "Yoga",
  },
  {
    value: "dance",
    name: "Dance Cardio",
  },
  {
    value: "barre",
    name: "Barre",
  },
  {
    value: "meditation",
    name: "Meditation",
  },
  {
    value: "holistic",
    name: "Holistic",
  },
  {
    value: "hiit",
    name: "HIIT",
  },
  {
    value: "stretching",
    name: "Stretching",
  },
  {
    value: "weights",
    name: "Weightlifting",
  },
  {
    value: "bodyweight",
    name: "Bodyweight Circuit",
  },
  {
    value: "prenatal",
    name: "Pre / Post Natal",
  },
];

function Profile() {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const cropperRef = useRef(null);

  /** Local state */
  const [imgFile, setFile] = useState(null);
  const [newImg, setNewImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [rand, updateRand] = useState(1234);

  /** Zustand state */
  const firstName = useBoundStore((state) => state.firstName);
  const lastName = useBoundStore((state) => state.lastName);
  const headline = useBoundStore((state) => state.headline);
  const bio = useBoundStore((state) => state.bio);
  const avatar = useBoundStore((state) => state.avatar);
  const displayName = useBoundStore((state) => state.displayName);
  const userType = useBoundStore((state) => state.userType);
  const mainCategory = useBoundStore((state) => state.mainCategory);
  const otherCats = useBoundStore((state) => state.otherCats);

  /** Zustand methods */
  const updateFirstName = useBoundStore((state) => state.updateFirstName);
  const updateLastName = useBoundStore((state) => state.updateLastName);
  const updateHeadline = useBoundStore((state) => state.updateHeadline);
  const updateBio = useBoundStore((state) => state.updateBio);
  const updateDisplayName = useBoundStore((state) => state.updateDisplayName);
  const updateAvatar = useBoundStore((state) => state.updateAvatar);
  const updateMainCat = useBoundStore((state) => state.updateMainCat);
  const updateOtherCats = useBoundStore((state) => state.updateOtherCats);

  const secondaryCategoryOpts = allCategories.filter(
    (c) => c.value !== mainCategory
  );

  const selectOtherCat = (value) => {
    let newSelected = otherCats?.split(",") || [];

    if (newSelected?.includes(value)) {
      newSelected = newSelected.filter((c) => c !== value);
    } else {
      if (newSelected.length > 2) return;
      newSelected.push(value);
    }

    const newCats = newSelected.join(",");

    updateOtherCats(newCats);
  };

  const selectMainCat = (value) => {
    updateMainCat(value);
    if (otherCats?.includes(value)) {
      selectOtherCat(value);
    }
  };

  const updateUserLocal = (user) => {
    const { bio, headline, firstName, lastName, avatar, mainGenre, genres } =
      user;
    updateFirstName(firstName);
    updateLastName(lastName);
    updateHeadline(headline);
    updateBio(bio);
    updateMainCat(mainGenre);
    if (avatar) updateAvatar(avatar);
    if (!avatar) updateAvatar(null);

    updateOtherCats(genres);

    return true;
  };

  const saveUser = async (user) => {
    setLoading(true);
    const res = await updateUserInfo(user);
    setLoading(false);
    setSuccess(Date.now());
  };

  useEffect(() => {
    async function fetchUserData() {
      if (user) {
        const userInfo = await getUserInfo(user.email);
        updateUserLocal(userInfo);
      }
    }
    fetchUserData();
  }, [user]);

  const dropProfilePic = async (files) => {
    if (!files) return;
    const file = files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setFile(reader?.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      setNewImg(blob);
    });
  };

  const submitPic = async () => {
    console.log("ABOUT TO SUBMIT PIC", user);
    setLoading(true);
    const newUser = await uploadAvatar(newImg, user?.email);

    setFile(null);
    setNewImg(null);
    updateAvatar(newUser?.avatar);
    setLoading(false);
  };

  const cancelPicEdit = () => {
    setFile(null);
    setNewImg(null);
    setLoading(false);
  };

  if (isLoading) return <PageLoader />;

  const creatorInputFields = (
    <>
      <Input
        title="First Name"
        value={firstName}
        onChange={(e) => updateFirstName(e.target.value)}
      />
      <Input
        title="Last Name"
        value={lastName}
        onChange={(e) => updateLastName(e.target.value)}
      />
      <Input
        title="Headline (one sentence)"
        value={headline}
        onChange={(e) => updateHeadline(e.target.value)}
        icon={<FiHelpCircle size={18} />}
        tooltipText={
          "Example 1: Enjoy an all-levels experience that seamlessly integrates optional full-body and cardio sequences<br /><br />Example 2: I focus on efficient but effective workouts in 15 minutes or less"
        }
      />
      {/* <div className="example-text">
        Headline example 1: My main focus is on dynamic stretching and full-body
        recovery
      </div>
      <div className="example-text">
        Headline Example 2: My main focus is on dynamic stretching and full-body
        recovery
      </div> */}
      <Input
        title="Fitness Philosophy"
        value={bio}
        multiline={true}
        onChange={(e) => updateBio(e.target.value)}
      />
      <SelectDrop
        title={"Main Fitness Category"}
        options={allCategories}
        value={mainCategory}
        onChange={(e) => selectMainCat(e.target.value)}
      />
      <div className="field upload-field">
        <label className="label">
          Select Other Relevant Categories (Up to 3)
        </label>
        <div className="box-grid">
          {secondaryCategoryOpts.map((c) => {
            const selected = otherCats?.includes(c?.value);
            return (
              <div
                className="box center-content clickable"
                key={c.value}
                onClick={() => selectOtherCat(c.value)}
                style={{
                  backgroundColor: selected ? "#685369" : "white",
                  color: selected && "white",
                  fontWeight: selected && 700,
                }}
              >
                {c.name}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );

  const userInputFields = (
    <>
      <Input
        title="First Name"
        value={firstName}
        onChange={(e) => updateFirstName(e.target.value)}
      />
      <Input
        title="Last Name"
        value={lastName}
        onChange={(e) => updateLastName(e.target.value)}
      />
      <Input
        title="Display Name"
        value={displayName}
        defaultValue={`${firstName} ${lastName}`}
        onChange={(e) => updateDisplayName(e.target.value)}
      />
    </>
  );

  const isCreator = userType && userType === "creator";

  return (
    <div className="flex-container">
      {!imgFile ? (
        <Dropzone onDrop={dropProfilePic}>
          {({ getRootProps, getInputProps }) => (
            <div className="drop-container" {...getRootProps()}>
              <div className="drop-cta"></div>
              <input {...getInputProps()} />
              {!avatar ? (
                <div className="drop-icon-circle" data-size="large">
                  <FiUpload className="drop-icon" size={60} />
                  <div className="large-text">Add Profile Pic</div>
                </div>
              ) : (
                <Avatar
                  url={`${avatar}?t=${rand}`}
                  height={240}
                  prompt={true}
                  promptText={"Update Pic"}
                  showPromptByDefault={true}
                />
              )}
            </div>
          )}
        </Dropzone>
      ) : (
        <>
          <Cropper
            src={imgFile && imgFile}
            style={{ height: 240, width: "50%", marginBottom: 8 }}
            // Cropper.js options
            aspectRatio={mainPicAspectRatio}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
          />
          <div className="buttons">
            <Button
              title={"Submit Picture"}
              onClick={submitPic}
              loading={loading}
              color={"var(--cta-color)"}
            />
            <Button
              title={"Cancel"}
              onClick={cancelPicEdit}
              color={"darkgray"}
            />
          </div>
        </>
      )}
      {isCreator ? creatorInputFields : userInputFields}
      <Button
        title={"Save"}
        color={"var(--cta-color)"}
        loading={loading}
        success={success}
        onClick={() =>
          saveUser({
            firstName,
            lastName,
            headline,
            bio,
            email: user?.email,
            displayName,
            userType,
            mainCategory,
            genres: otherCats,
          })
        }
      />
    </div>
  );
}

export default withAuthenticationRequired(Profile);
