import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getUserInfo,
  createStripeAcct,
  uploadAvatar,
  updateUserInfo,
  putPricing,
} from "../utility";
import { useBoundStore } from "../state/store";
import Button from "../components/Button";
import SelectDrop from "../components/SelectDrop";

const monthlyOptions = [
  {
    name: "$9.99",
    value: 999,
  },
  {
    name: "$14.99",
    value: 1499,
  },
  {
    name: "$19.99",
    value: 1999,
  },
  {
    name: "$24.99",
    value: 2499,
  },
  {
    name: "$29.99",
    value: 2999,
  },
  {
    name: "$34.99",
    value: 3499,
  },
  {
    name: "$39.99",
    value: 3999,
  },
  {
    name: "$49.99",
    value: 4999,
  },
  {
    name: "$59.99",
    value: 5999,
  },
  {
    name: "$69.99",
    value: 6999,
  },
  {
    name: "$79.99",
    value: 7999,
  },
  {
    name: "$89.99",
    value: 8999,
  },
  {
    name: "$99.99",
    value: 9999,
  },
];

const annualOptions = [
  {
    name: "$99.99",
    value: 9999,
  },
  {
    name: "$119.99",
    value: 12999,
  },
  {
    name: "$149.99",
    value: 14999,
  },
  {
    name: "$199.99",
    value: 19999,
  },
  {
    name: "$249.99",
    value: 24999,
  },
  {
    name: "$299.99",
    value: 29999,
  },
  {
    name: "$349.99",
    value: 34999,
  },
  {
    name: "$399.99",
    value: 39999,
  },
  {
    name: "$499.99",
    value: 49999,
  },
  {
    name: "$599.99",
    value: 59999,
  },
  {
    name: "$699.99",
    value: 69999,
  },
  {
    name: "$799.99",
    value: 79999,
  },
  {
    name: "$899.99",
    value: 89999,
  },
  {
    name: "$999.99",
    value: 99999,
  },
];

function Payments() {
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  /** Zustand state */
  const firstName = useBoundStore((state) => state.firstName);
  const lastName = useBoundStore((state) => state.lastName);
  const headline = useBoundStore((state) => state.headline);
  const bio = useBoundStore((state) => state.bio);
  const avatar = useBoundStore((state) => state.avatar);
  const displayName = useBoundStore((state) => state.displayName);
  const userType = useBoundStore((state) => state.userType);
  const mainCategory = useBoundStore((state) => state.mainCategory);
  const otherCats = useBoundStore((state) => state.otherCats);
  const acctReady = useBoundStore((state) => state.acctReady);
  const acctId = useBoundStore((state) => state.acctId);
  const mPrice = useBoundStore((state) => state.mPrice);
  const aPrice = useBoundStore((state) => state.aPrice);

  /** Zustand methods */
  const updateFirstName = useBoundStore((state) => state.updateFirstName);
  const updateLastName = useBoundStore((state) => state.updateLastName);
  const updateHeadline = useBoundStore((state) => state.updateHeadline);
  const updateBio = useBoundStore((state) => state.updateBio);
  const updateDisplayName = useBoundStore((state) => state.updateDisplayName);
  const updateAvatar = useBoundStore((state) => state.updateAvatar);
  const updateMainCat = useBoundStore((state) => state.updateMainCat);
  const updateAcctStatus = useBoundStore((state) => state.updateAcctStatus);
  const updateAcctId = useBoundStore((state) => state.updateAcctId);
  const updateOtherCats = useBoundStore((state) => state.updateOtherCats);
  const updatePrices = useBoundStore((state) => state.updatePrices);

  const updateUserLocal = (user) => {
    const {
      bio,
      headline,
      firstName,
      lastName,
      avatar,
      mainGenre,
      genres,
      acctReady,
      payAcct,
      mPrice,
      aPrice,
    } = user;
    updateFirstName(firstName);
    updateLastName(lastName);
    updateHeadline(headline);
    updateBio(bio);
    updateMainCat(mainGenre);
    if (avatar) updateAvatar(avatar);
    if (!avatar) updateAvatar(null);

    updateOtherCats(genres);
    updateAcctId(payAcct);
    updateAcctStatus(acctReady);
    if (mPrice && aPrice) updatePrices({ aPrice, mPrice });

    return true;
  };

  const setUpPayments = async () => {
    try {
      setLoading(true);
      const linkInfo = await createStripeAcct();
      setLoading(false);
      console.log("linkInfo", linkInfo);
      if (linkInfo?.url) window.location.href = linkInfo?.url;
    } catch (err) {
      console.error("error redirecting to stripe");
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchUserData() {
      if (user) {
        const userInfo = await getUserInfo(user.email);
        console.log("user info---", userInfo);
        updateUserLocal(userInfo);
      }
    }
    fetchUserData();
  }, [user]);

  const onChangePrice = (interval, newPrice) => {
    if (interval !== "month" && interval !== "year") return;
    if (interval === "month") updatePrices({ mPrice: newPrice, aPrice });
    else updatePrices({ aPrice: newPrice, mPrice });
  };

  const submitPrices = async () => {
    try {
      console.log("in submit prices 1");
      const prices = { aPrice, mPrice };
      setLoading(true);
      console.log("in submit prices 2");
      await putPricing(prices);
      console.log("in submit prices 3");
      setSuccess(Date.now());

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      // Alert User to Error
    }
  };

  const accountNotSet = (
    <>
      <p>You must create a Stripe account in order to accept payments</p>
      <p>This should only take a couple of minutes</p>
      <Button
        title="Set Up Payments"
        onClick={setUpPayments}
        loading={loading}
        color={"var(--cta-color)"}
      />
    </>
  );

  const accountStarted = (
    <>
      <p className="large-text">{`You're so close to accepting payments!`}</p>
      <p className="standard-text">This should only take a couple of minutes</p>
      <Button
        title="Set Up Payments"
        onClick={setUpPayments}
        loading={loading}
        color={"var(--cta-color)"}
      />
    </>
  );

  const pricingInfo = (
    <>
      <p className="large-text">Set Pricing</p>
      <i className="spacing-bottom">
        Minimum Pricing on our Platform is $9.99 / month or $149.99 / year
      </i>
      <SelectDrop
        title="Monthly Price"
        options={monthlyOptions}
        value={mPrice}
        width={125}
        onChange={(e) => onChangePrice("month", e.target.value)}
      />
      <SelectDrop
        title="Annual Price"
        options={annualOptions}
        value={aPrice}
        width={125}
        onChange={(e) => onChangePrice("year", e.target.value)}
      />
      <Button
        title="Save Pricing"
        loading={loading}
        onClick={submitPrices}
        success={success}
        color={"var(--cta-color)"}
      />
    </>
  );

  //   if (acctReady) return pricingInfo;

  return (
    <div className="flex-container">
      {acctReady && pricingInfo}
      {!acctReady && !acctId && accountNotSet}
      {!acctReady && acctId && accountStarted}
    </div>
  );
}

export default Payments;
