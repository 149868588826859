const initialState = {
  currentVid: null,
  featuredCreators: [],
};

export const customerSlice = (set) => ({
  ...initialState,
  updateCurrentVid: (currentVid) => set(() => ({ currentVid })),
  updateFeatured: (featuredCreators) => set(() => ({ featuredCreators })),
});
