import React, { useEffect, useState } from "react";
import {
  useParams,
  useRouteMatch,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import { getFeaturedCreators } from "../utility";
import ContentBox from "../components/ContentBox";
import CardCarousel from "../components/CardCarousel";
import { useBoundStore } from "../state/store";

const instructors = [
  { name: "John Henry" },
  { name: "Geetika Rudra" },
  { name: "Stephan Nissan" },
  { name: "Rebecca Nash" },
  { name: "JJ Denver" },
  { name: "JJ Denver" },
  { name: "JJ Denver" },
  { name: "JJ Denver" },
  { name: "JJ Denver" },
];

const categories = [
  {
    name: "yoga",
    displayName: "Yoga",
    tagline: "",
  },
  {
    name: "dance",
    displayName: "Dance Cardio",
    tagline: "",
  },
  {
    name: "barre",
    displayName: "Barre",
    tagline: "",
  },
];

function Market() {
  const [allFeatured, updateAllFeatured] = useState([]);
  const { path, url } = useRouteMatch();
  const { cat } = useParams();
  const history = useHistory();

  /** Zustand state */
  const featuredCreators = useBoundStore((store) => store.featuredCreators);

  /** Zustand methods */
  const updateFeaturedCreators = useBoundStore((store) => store.updateFeatured);

  const { search } = useLocation();

  const onSelectCard = (u) => {
    if (u?.username) history.push(`/creator/${u.username}`);
    else history.push(`/creator/${"john-henry"}`);
  };

  useEffect(() => {
    async function getCreators() {
      const creators = await getFeaturedCreators("all", search);
      // updateAllFeatured(creators);
      updateFeaturedCreators(creators);
    }
    getCreators();
  }, []);

  const currentCat = categories.find(({ name }) => name === cat);

  return (
    <div className="start-top-container">
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          position: "relative",
        }}
      >
        <div>
          <div className="navbar subnav">
            <Link to={`/market/yoga`}>
              <div className="navbar-item">Yoga</div>
            </Link>
            <Link to={`/market/dance`}>
              <div className="navbar-item">Dance Cardio</div>
            </Link>
            <Link to={`/market/barre`}>
              <div className="navbar-item">Barre</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="banner background-horizontal-1">
        {/* <img
          src="/horizontal_workout_6.jpeg"
          width={"100%"}
          style={{ maxHeight: 600 }}
        /> */}
        <ContentBox
          style={{
            position: "absolute",
            left: 24,
            top: 24,
            backgroundColor: "white",
          }}
          title={"Gym is a mindset"}
          bullet={
            <div>
              We make at-home exercise easy and fun. You&apos;re one-click away
              from <strong>your</strong> fitness adventure!
            </div>
          }
        />
      </div>
      <section className="creator-section-container background-med1">
        <h6 className="section-title">
          Find where <i>you</i> belong in the TechTonic community!
        </h6>
      </section>
      <CardCarousel
        users={featuredCreators}
        title={`Popular ${currentCat?.displayName || "Yoga"} Instructors`}
        onSelectCard={onSelectCard}
      />
      <CardCarousel users={instructors} title={`You may also like`} />
    </div>
  );
}

export default Market;
