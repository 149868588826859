import React, { useEffect, useState } from "react";
import Thumbnail from "./Thumbnail";
import PlayerContainer from "./PlayerContainer";
import Input from "./Input";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { ImSpinner11 } from "react-icons/im";
import { useBoundStore } from "../state/store";
import { getThumbnailUrlFromIndex } from "../utility/videoHelpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function UploadForm({
  vidId,
  readyToStream = false,
  pctComplete,
  dur = 60,
  editing = false,
}) {
  /** Zustand Upload / Edit Context */
  const vidMetadata = useBoundStore((state) => state.vidMetadata);
  const tusUploadStatus = useBoundStore((state) => state.tusUploadStatus);
  const vidUploadStatus = useBoundStore((state) => state.vidUploadStatus);
  const formMode = useBoundStore((state) => state.formMode);

  /** Zustand Form Input Fields */
  const releaseOpt = useBoundStore((state) => state.releaseOpt);
  const releaseDate = useBoundStore((state) => state.releaseDate);
  const title = useBoundStore((state) => state.title);
  const desc = useBoundStore((state) => state.desc);
  const thumbTs = useBoundStore((state) => state.thumbTs);

  /** Zustand actions */
  const updateDesc = useBoundStore((state) => state.updateDesc);
  const updateTitle = useBoundStore((state) => state.updateTitle);
  const updateThumbnail = useBoundStore((state) => state.updateThumbnail);
  const updateReleaseOpt = useBoundStore((state) => state.updateReleaseOpt);
  const updateReleaseDate = useBoundStore((state) => state.updateReleaseDate);

  /** Local state */
  const initialThumbnail = thumbTs / 20;
  const [selectedThumbnail, chooseThumbnail] = useState(initialThumbnail);
  const [startThumbnail, updateStart] = useState(initialThumbnail);

  const shiftThumbnails = (direction = "forward") => {
    if (direction === "forward") {
      updateStart(startThumbnail + 2);
      selectThumbnail(startThumbnail + 2);
      return;
    }

    const minIndex = Math.max(startThumbnail - 2, 0);
    updateStart(minIndex);
    selectThumbnail(minIndex);
  };

  const selectThumbnail = (index, url) => {
    chooseThumbnail(index);
    updateThumbnail(index);
  };

  const getProgBarTitle = () => {
    if (!pctComplete) return `Uploading`;

    if (pctComplete === 100) return "";

    if (tusUploadStatus === "uploading")
      return `Uploading ${pctComplete}% complete`;

    return `Processing ${pctComplete}% complete`;
  };

  const radioSelect = (e) => {
    updateReleaseOpt(e.target.name);
  };

  const selectReleaseDate = (date, param2) => {
    updateReleaseDate(date);
  };

  /** We show 3 thumbnail options at a time, so we want to cache the subsequent out of view thumbnails */
  const cacheThumbnails = (startInd = startThumbnail + 3, num = 3) => {
    const urls = [];

    for (let i = startInd; i <= startInd + num; i++) {
      const url = getThumbnailUrlFromIndex(vidId, i);
      urls.push(url);
    }

    urls.forEach((url, i) => {
      const img = new Image();
      img.src = url;
      img.onload = () => null;
    });
  };

  useEffect(() => {
    /** Release Date Format Check */

    if (releaseDate && typeof releaseDate != "object") {
      const newDate = new Date(releaseDate);
      updateReleaseDate(newDate);
    }
  }, []);

  useEffect(() => {
    /** Prefetch next thumbnail images */

    if (editing || vidUploadStatus === "done") {
      const startInd =
        startThumbnail === 0 ? startThumbnail + 3 : startThumbnail + 6;
      cacheThumbnails(startInd, 6);
    }
  }, [startThumbnail, vidUploadStatus]);

  return (
    <div className="upload-form-container">
      <div className="upload-form-fields">
        <div className="field modal-upload-field">
          <Input
            title={"Workout Title (max 40 characters)"}
            onChange={(e) => updateTitle(e?.target?.value)}
            placeholder={"Ex. Vinyasa Yoga"}
            width={"100%"}
            maxChar={40}
            value={title}
          />
        </div>
        <div className="field modal-upload-field">
          <Input
            title={"Description"}
            onChange={(e) => updateDesc(e?.target?.value)}
            placeholder="Ex. HIIT, Cardio, Abs, Weights, etc."
            width={"100%"}
            multiline={true}
            value={desc}
          />
        </div>
        <div className="field modal-upload-field">
          <label className="label">Select Thumbnail</label>
          <div className="control">
            <div className="thumbnail-container">
              <FiChevronLeft
                size={32}
                className="clickable-icon"
                onClick={() => shiftThumbnails("backward")}
              />
              <Thumbnail
                key={startThumbnail}
                aspectRatio={
                  (vidMetadata && vidMetadata.width / vidMetadata.height) || 1
                }
                vidId={vidId}
                vidUploading={!readyToStream && !editing}
                url={getThumbnailUrlFromIndex(vidId, startThumbnail)}
                opaque={selectedThumbnail !== startThumbnail}
                select={(url) => selectThumbnail(startThumbnail, url)}
              ></Thumbnail>
              <Thumbnail
                key={startThumbnail + 1}
                aspectRatio={
                  (vidMetadata && vidMetadata.width / vidMetadata.height) || 1
                }
                vidId={vidId}
                url={getThumbnailUrlFromIndex(vidId, startThumbnail + 1)}
                vidUploading={!readyToStream && !editing}
                opaque={selectedThumbnail !== startThumbnail + 1}
                select={(url) => selectThumbnail(startThumbnail + 1, url)}
              ></Thumbnail>
              <Thumbnail
                key={startThumbnail + 2}
                aspectRatio={
                  (vidMetadata && vidMetadata.width / vidMetadata.height) || 1
                }
                url={getThumbnailUrlFromIndex(vidId, startThumbnail + 2)}
                vidUploading={!readyToStream && !editing}
                opaque={selectedThumbnail !== startThumbnail + 2}
                select={(url) => selectThumbnail(startThumbnail + 2, url)}
              ></Thumbnail>
              <FiChevronRight
                size={32}
                className="clickable-icon"
                onClick={() => shiftThumbnails("forward")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="upload-preview">
        <PlayerContainer
          vidId={vidId}
          readyToStream={readyToStream || editing}
          pctComplete={pctComplete}
          width={vidMetadata?.width}
          height={vidMetadata?.height}
          size="small"
        />
        {!editing && pctComplete < 100 ? (
          <div className={`progress-bar-wrapper`}>
            <div className="progress-bar-title-wrapper">
              <ImSpinner11 className="spinning-icon" size={16} />
              <div className="progress-bat-title">{getProgBarTitle()}</div>
            </div>
            <progress
              className="progress is-success"
              value={pctComplete || 10}
              max="100"
            ></progress>
          </div>
        ) : (
          <div />
        )}
        <div className="control upload-scheduler-wrapper">
          <div className="upload-radio-wrapper">
            <label className="label">Set Visibility</label>
            <label className="radio">
              <input
                className="radio-spacing"
                type="radio"
                name="draft"
                onClick={radioSelect}
                checked={releaseOpt === "draft"}
              />
              Keep private
            </label>
            <label className="radio">
              <input
                className="radio-spacing"
                type="radio"
                name="publish"
                onClick={radioSelect}
                checked={releaseOpt === "publish"}
              />
              Publish now
            </label>
            <label className="radio">
              <input
                className="radio-spacing"
                type="radio"
                name="schedule"
                onClick={radioSelect}
                checked={releaseOpt === "schedule"}
              />
              Schedule release
            </label>
          </div>
          {releaseOpt === "schedule" && (
            <DatePicker
              selected={typeof releaseDate === "object" && releaseDate}
              showTimeSelect
              placeholderText="Select a release date"
              popperPlacement="left-end"
              onChange={selectReleaseDate}
              dateFormat="MMM d, yyyy h:mm aa"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadForm;
