import React, { useState } from "react";

function SelectDrop({
  options = [{ name: "test", value: "test" }],
  title = "Test",
  width,
  onChange,
  value = null,
}) {
  const [hovering, setHovering] = useState(false);

  return (
    <div className="field upload-field" style={{ width }}>
      <label className="label">{title}</label>
      <div className="select">
        <select onChange={onChange} value={value}>
          {options.map((o, i) => {
            return (
              <option key={i} value={o.value}>
                {o.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default SelectDrop;
