import { spaceBetweenThumbnails } from "./rules";

export const generateThumbnailUrl = (
  vidId,
  seconds = 0,
  height = 120,
  dur = 100000
) => {
  const maxSeconds = Math.min(seconds, dur);
  const timeParam = String(maxSeconds) + "s";
  return `https://customer-i7c3d2jusxqj1azx.cloudflarestream.com/${vidId}/thumbnails/thumbnail.jpg?time=${timeParam}&height=${height}`;
};

export const getThumbnailUrlFromIndex = (vidId, index, height = 240) => {
  const seconds = index * spaceBetweenThumbnails;
  const url = generateThumbnailUrl(vidId, seconds, height);

  return url;
};
