import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import {
  getCreatorInfo,
  getCreatorPreview,
  postCheckoutSession,
} from "../utility";
import Card from "../components/Card";
import Button from "../components/Button";
import PageLoader from "../components/PageLoader";

function CreatorInfo() {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const history = useHistory();
  const { username } = useParams();

  const [rand, updateRand] = useState(Math.random());
  const [creator, updateCreator] = useState({});
  const [creatorVids, updateCreatorVids] = useState([]);

  useEffect(() => {
    async function getCreator() {
      /** Update with fn to pull creator's info */
      const userInfo = await getCreatorInfo(username);
      updateCreator(userInfo);
    }
    async function getCreatorVids() {
      // if (user) {
      /** Update with fn to pull creator's info */
      const userVids = await getCreatorPreview(username);
      updateCreatorVids(userVids.slice(0, 3));
    }

    getCreator();
    getCreatorVids();
    const rand = Math.random();
    updateRand(rand);
  }, [user?.email]);

  const getCheckoutSession = async (interval = "month") => {
    let priceId;
    if (interval === "month") priceId = creator?.mPriceId;
    if (interval === "year") priceId = creator?.aPriceId;

    if (!priceId) return null;

    const checkoutSession = await postCheckoutSession(priceId, username);

    if (checkoutSession?.url) window.location.href = checkoutSession.url;
  };

  if (isLoading || !creator?.email) return <PageLoader />;

  return (
    <div className="creator-info-container">
      <img className="creator-info-img" src={creator?.avatar} />
      <h2 className="page-title">{`${creator.firstName} ${creator.lastName}`}</h2>
      <p className="creator-headline-text">{creator.headline}</p>
      <section className="creator-section-container">
        <h6 className="section-title">Fitness Philosophy</h6>
        <div>{`${creator.bio}`}</div>
      </section>
      <section className="creator-section-container">
        <h6 className="section-title">Bio</h6>
        <p className="creator-section-text">{`${creator.bio}`}</p>
      </section>
      <section className="access-info">
        <h6 className="section-title">Get Access!</h6>
        <div className="access-info-cta">
          <Button
            title={`$${creator?.mPrice / 100} / Month`}
            onClick={() => getCheckoutSession("month")}
            color={"#685efc"}
          />
          <div style={{ height: 12 }}></div>
          <Button
            title={`$${creator?.aPrice / 100} / Year`}
            onClick={() => getCheckoutSession("year")}
            color={"#685efc"}
          />
        </div>
      </section>
      <section className="creator-section-container">
        <h6 className="section-title">Recent Workouts</h6>
        <div className="creator-vid-wrapper">
          {creatorVids.map((v) => {
            return (
              <Card
                key={v.title}
                title={v.title}
                smallUri={creator?.avatar}
                mainUri={v.thumb && `${v?.thumb}`}
                desc={v.desc}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default CreatorInfo;
