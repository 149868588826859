import React from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

function Input({
  defaultValue = "",
  title = "Test Input",
  value,
  width,
  onChange,
  multiline = false,
  icon = null,
  tooltipText = "Hello World",
  maxChar = 1000,
}) {
  return (
    <div className="field upload-field" style={{ width }}>
      <div className="label">
        <label className="label-text">{title}</label>
        {icon && (
          <div
            id={`attributes-variant-${title}`}
            data-tooltip-variant="info"
            data-tooltip-html={tooltipText}
            style={{ display: "flex", maxWidth: 24, alignItems: "center" }}
          >
            {icon}
            <Tooltip anchorId={`attributes-variant-${title}`} place="top" />
          </div>
        )}
      </div>
      <div className="control">
        {!multiline ? (
          <input
            className="input"
            type="text"
            placeholder="Ex. Vinyasa Yoga"
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            maxLength={maxChar}
          />
        ) : (
          <textarea
            className="textarea"
            placeholder="Ex. HIIT, Cardio, Abs, Weights, etc."
            onChange={onChange}
            value={value}
          ></textarea>
        )}
      </div>
    </div>
  );
}

export default Input;
