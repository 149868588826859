import "cropperjs/dist/cropper.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { FiMoreHorizontal } from "react-icons/fi";
import { getSubscriptions } from "../utility";
import PageLoader from "../components/PageLoader";
import Button from "../components/Button";
import { mainPicAspectRatio } from "../utility/rules";
import { useBoundStore } from "../state/store";

const avatarHeight = 80;

function Subscriptions() {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const history = useHistory();

  /** Local state */
  const [loading, setLoading] = useState(false);
  const [activeInd, setActive] = useState(null);

  console.log("active index", activeInd);

  /** Zustand State */
  const subs = useBoundStore((store) => store?.subs);

  const updateSubs = useBoundStore((store) => store?.updateSubs);

  console.log("Subs", subs);

  useEffect(() => {
    async function refreshSubs() {
      setLoading(true);
      console.log("here");
      const subscriptions = await getSubscriptions();
      console.log("subs", subscriptions);
      updateSubs(subscriptions);
      setLoading(false);
    }
    refreshSubs();
  }, []);

  const navToLib = (username) => {
    history.push(`/library/${username}`);
  };

  const navToManage = (e) => {
    e.stopPropagation();
    window.open("https://billing.stripe.com/p/login/test_eVafZk6p5doI6f6aEE");
  };

  const updateActive = (index, e) => {
    e.stopPropagation();
    if (activeInd !== index) setActive(index);
    else setActive(null);
  };

  const selectCategory = (cat) => {
    history.push(`search?s=${cat}`);
  };

  if (loading) return <PageLoader />;

  return (
    <div className="flex-container">
      <div className="subscription-content-container">
        <div className="large-text">Active Subscriptions</div>
        {subs.map((sub, i) => {
          return (
            <div
              key={sub?.creator}
              className="subscription-row clickable"
              onClick={navToLib.bind(null, sub?.cUsername)}
            >
              <img
                src={sub?.cAvatar}
                width={mainPicAspectRatio * avatarHeight}
                height={avatarHeight}
                className="sub-img"
              />
              <div>
                <div className="bold-text">{sub?.cName} </div>
                <div className={`sub-status ${sub?.expired && "expired"}`}>
                  {sub?.expired ? "Expired" : ""}{" "}
                </div>
              </div>
              <div
                className={`sub-manage dropdown ${
                  activeInd === i && "is-active"
                }`}
              >
                <FiMoreHorizontal
                  size={24}
                  className="dropdown-trigger"
                  onClick={updateActive.bind(null, i)}
                />
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    <div onClick={navToManage} className="dropdown-item">
                      Manage Subscription
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {!subs?.length && (
          <>
            <div className="standard-text">
              You do not have any active subscriptions (yet!)
            </div>
            <div className="bold-text underline">
              Select the category that interests you the most
            </div>
            <div className="button-container">
              <Button
                title={"Yoga"}
                onClick={selectCategory.bind(null, "yoga")}
              />
              <Button
                title={"HIIT"}
                onClick={selectCategory.bind(null, "hiit")}
              />
              <Button
                title={"Dance"}
                onClick={selectCategory.bind(null, "dance")}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Subscriptions);
