import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { getCreatorInfo, getCreatorPreview, getUserVids } from "../utility";
import Card from "../components/Card";
import Unauthorized from "../components/Unauthorized";
import PageLoader from "../components/PageLoader";

function CreatorLibrary() {
  const { isLoading, user } = useAuth0();
  const history = useHistory();
  const { username } = useParams();

  /** Local state */
  const [creator, updateCreator] = useState({});
  const [creatorVids, updateCreatorVids] = useState([]);
  const [blocked, updateBlocked] = useState(false);

  useEffect(() => {
    async function getCreator() {
      /** Update with fn to pull creator's info */
      const userInfo = await getCreatorInfo(username);

      updateCreator(userInfo);
    }

    async function getCreatorVids() {
      try {
        // if (user) {
        /** Update with fn to pull creator's info */
        const userVids = await getUserVids(username);

        updateCreatorVids(userVids || []);
      } catch (err) {
        if (err?.code === 401) updateBlocked(true);
      }
    }

    getCreator();
    getCreatorVids();
  }, [user?.email]);

  const selectVid = (vid) => {
    console.log("selected vid", vid, `/vid/${username}/${vid?.id}`);

    history.push(`/vid/${username}/${vid?.id}`);
  };

  if (blocked) return <Unauthorized username={username} />;

  if (isLoading || !creator?.email) return <PageLoader />;

  return (
    <div className="creator-info-container">
      <img className="creator-info-img" src={creator?.avatar} />
      <h2 className="page-title">{`${creator.firstName} ${creator.lastName} Content`}</h2>
      <p className="creator-headline-text">{creator.headline}</p>
      <section className="creator-section-container">
        <h6 className="section-title">Recent Workouts</h6>
        <div className="creator-vid-wrapper">
          {creatorVids.map((v) => {
            return (
              <Card
                key={v.title}
                title={v.title}
                smallUri={creator?.avatar}
                mainUri={v.thumb && `${v?.thumb}`}
                desc={v.desc}
                onClick={() => selectVid(v)}
              />
            );
          })}
        </div>
      </section>
      <section className="creator-section-container">
        <h6 className="section-title">Most Viewed</h6>
        <div className="creator-vid-wrapper">
          {creatorVids.map((v) => {
            return (
              <Card
                key={v.title}
                title={v.title}
                smallUri={creator?.avatar}
                mainUri={v.thumb && `${v?.thumb}`}
                desc={v.desc}
                onClick={() => selectVid(v)}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default withAuthenticationRequired(CreatorLibrary);
