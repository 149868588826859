import React from "react";

function ContentBox({
  title = "Today is your day",
  bullet = "Investing in yourself, made easier! Find an inspiring instructor for as low as $9.99 - today!",
  style,
}) {
  return (
    <div className="content-box" style={{ ...style }}>
      <div className="content-box-title">{title}</div>
      <div className="content-box-bullet">{bullet}</div>
    </div>
  );
}

export default ContentBox;
