import React, { useEffect } from "react";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { useAuth0 } from "@auth0/auth0-react";
import { addAuthHeader } from "../utility/client";
import { getUserInfo } from "../utility";
import NavBar from "../components/NavBar";
import { syncVid } from "../utility";
import { useBoundStore } from "../state/store";

// let uppy;

// if (!uppy) {
//   uppy = new Uppy();
// }

const uppy = new Uppy();

const tusUpload = async (file, url, cb, token) => {
  console.log("in tus upload---", token);
  uppy
    .use(Tus, {
      id: Date.now(),
      endpoint: url,
      chunkSize: 150 * 1024 * 1024,
      headers: {
        // Authorization: `Bearer ${token}`,
      },
      onBeforeRequest: (req) => {
        const fullUrl = req["_url"];

        console.log("FULL URL 1-----\n", req);

        if (!fullUrl.includes("videodelivery")) {
          var xhr = req.getUnderlyingObject();
          console.log("\n\nxhr\n\n", xhr, xhr.headers);

          xhr.setRequestHeader("Authorization", `Bearer ${token}`);
          req._headers.Authorization = `Bearer ${token}`;
        } else {
          const section = fullUrl.split("/tus/")[1];
          const id = section.split("?tus")[0];

          if (id && String(id).length > 10) cb("vidId", id);
        }
      },
    })
    .on("upload-success", (file, response) => {
      cb("tusUploadStatus", "done");
      cb("vidStatus", "done");
      cb("progress", 0);
      // cb("resetForm");
    });

  uppy.on("file-added", (file) => {
    console.log("Added file", file);
  });

  uppy.on("upload", (data) => {
    console.log(`Starting upload`, data);
    cb("vidStatus", "uploading");
    cb("tusUploadStatus", "uploading");
  });

  uppy.on("progress", (progress) => {
    console.log("uppy progress--", progress);
    const maxProg = Math.min(progress, 99);
    cb("progress", maxProg);
    cb("tusUploadStatus", "uploading");
  });

  uppy.on("complete", (result) => {
    console.log("successful files:", result.successful);
    console.log("failed files:", result.failed);
    cb("tusUploadStatus", "done");
    cb("progress", 0);
  });

  uppy.on("upload-error", (file, error, response) => {
    console.log("error with file:", file.id);
    console.log("error message:", error);
    cb("vidStatus", "error");
  });

  uppy.addFile(file);

  await uppy.upload();
};

const resumeUpload = async (currentFile, url, cb) => {
  try {
    /**
     * TODO: This may need to use the cached fileId and filename to
     * re-initiate an upload from scratch (in the scenario that the user hard refreshes mid-upload)
     * */

    const filesInProgress = await uppy.getFiles();

    if (currentFile && url && !filesInProgress?.length) {
      return tusUpload(currentFile, url, cb);
    }

    console.log("FILES IN PROGRESS----", filesInProgress);
  } catch (err) {
    console.log("FAILED TO RESUME UPLOAD", err);
  }
};

function Root({ children }) {
  const { getAccessTokenSilently: getToken, user } = useAuth0();

  /** Zustand State */
  const vidUploadStatus = useBoundStore((state) => state.vidUploadStatus);
  const vidId = useBoundStore((state) => state.vidId);

  /** Zustand Methods */
  const updateFirstName = useBoundStore((state) => state.updateFirstName);
  const updateLastName = useBoundStore((state) => state.updateLastName);
  const updateHeadline = useBoundStore((state) => state.updateHeadline);
  const updateBio = useBoundStore((state) => state.updateBio);
  const updateAvatar = useBoundStore((state) => state.updateAvatar);
  const updateUserType = useBoundStore((state) => state.updateUserType);

  const updateUserLocal = (user) => {
    const {
      bio,
      headline,
      firstName,
      lastName,
      avatar,
      userType = "user",
    } = user;
    updateFirstName(firstName);
    updateLastName(lastName);
    updateHeadline(headline);
    updateBio(bio);
    updateUserType(userType);
    if (avatar) updateAvatar(avatar);
    if (!avatar) updateAvatar(null);

    return true;
  };

  useEffect(() => {
    if (vidUploadStatus === "done") {
      syncVid(vidId);
    }
  }, [vidUploadStatus]);

  useEffect(() => {
    addAuthHeader(getToken);
  }, [getToken]);

  useEffect(() => {
    async function fetchUserData() {
      if (user) {
        const userInfo = await getUserInfo(user.email);
        updateUserLocal(userInfo);
      }
    }
    fetchUserData();
  }, [user]);

  return (
    <>
      <NavBar tusUpload={tusUpload} resume={resumeUpload} />
      {children}
    </>
  );
}

export default Root;
