import React, { useState } from "react";
import { useEffect } from "react";
import { FiCheckCircle } from "react-icons/fi";

function Button({
  height = 50,
  title = "Edit Workout",
  onClick,
  color = null,
  success = 1234,
  loading = false,
  icon = null,
  primary = true,
  textColor = "white",
  strong = true,
  disabled = false,
}) {
  const trueColor = color ? color : primary ? "var(--primary-color)" : null;

  const [successIteration, updateSuccessIter] = useState(1234);
  const [successAnimation, updateSuccess] = useState(false);

  useEffect(() => {
    if (success && success > successIteration) {
      updateSuccessIter(success);
      updateSuccess(true);
      setTimeout(() => updateSuccess(false), 2000);
    }
  }, [success]);

  let titleJSX = (
    <strong style={{ color: textColor, visibility: loading && "hidden" }}>
      {title}
    </strong>
  );

  if (!strong)
    titleJSX = (
      <p style={{ color: textColor, visibility: loading && "hidden" }}>
        {title}
      </p>
    );

  return (
    <button
      className={`button ${primary && "is-primary"} ${loading && "is-loading"}`}
      onClick={onClick}
      style={{
        backgroundColor: trueColor,
        marginBottom: 0,
        borderColor: "transparent",
      }}
      disabled={disabled}
    >
      <div className={`button-content ${successAnimation && "success"}`}>
        {icon && icon}
        {titleJSX}
      </div>
      <div className={`button-success-icon ${successAnimation && "success"}`}>
        <FiCheckCircle size={26} />
      </div>
    </button>
  );
}

export default Button;
