import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import PageLoader from "../components/PageLoader";

function VideoScreen() {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const history = useHistory();
  const { username, vidId } = useParams();

  if (isLoading) return <PageLoader />;

  return (
    <div>
      <div style={{ maxHeight: "90%" }}>
        <div style={{ position: "relative", paddingTop: "56.25%" }}>
          <iframe
            src={`https://customer-i7c3d2jusxqj1azx.cloudflarestream.com/${vidId}/iframe`}
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(VideoScreen);
