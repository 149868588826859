import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
const { REACT_APP_AUTH0_DOMAIN: domain, REACT_APP_AUTH0_CLIENTID: clientId } =
  process.env;

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={"dev-565frqwb.us.auth0.com"}
      clientId={"ebFxJld40hm9dYRzz4ka9m0PCteDHIiC"}
      redirectURI={`${window.location.origin}`}
      redirectUri={`${window.location.origin}`}
      onRedirectCallback={onRedirectCallback}
      audience={"https://dev-565frqwb.us.auth0.com/api/v2/"}
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
