import "bulma/css/bulma.css";
import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Root from "./layout/RootLayout";
import ScrollToTop from "./layout/ScrollToTop";
import CreatorDash from "./screens/CreatorDash";
import CreatorInfo from "./screens/CreatorInfo";
import SearchResults from "./screens/SearchResults";
import Profile from "./screens/Profile";
import MarketEntry from "./screens/MarketEntry";
import Payments from "./screens/Payments";
import CreatorLibrary from "./screens/CreatorLibrary";
import Subscriptions from "./screens/Subscriptions";
import Video from "./screens/Video";
import Auth0ProviderWithHistory from "./Auth/Auth0ProviderWithHistory";
import { useBoundStore } from "./state/store";

function App() {
  const updateFormOpen = useBoundStore((state) => state.updateFormOpen);
  const tusUploadStatus = useBoundStore((state) => state.tusUploadStatus);

  useEffect(() => {
    /** This tells the browser to present a warning to the user prior to refreshing
     * With the tus upload protocol, an upload attempt will not persist through a refresh
     */
    window.onbeforeunload = function () {
      if (tusUploadStatus === "uploading") {
        return "If you reload this page, your previous action will be repeated";
      } else {
        //Don't return anything
      }
    };
  });

  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <>
          <Root />
          <ScrollToTop>
            <Switch>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/dashboard">
                <CreatorDash />
              </Route>
              <Route path="/payments">
                <Payments />
              </Route>
              <Route path="/market/:cat?">
                <MarketEntry />
              </Route>
              <Route path="/creator/:username">
                <CreatorInfo />
              </Route>
              <Route path="/library/:username">
                <CreatorLibrary />
              </Route>
              <Route path="/vid/:username/:vidId">
                <Video />
              </Route>
              <Route path="/subscriptions">
                <Subscriptions />
              </Route>
              <Route path="/search">
                <SearchResults />
              </Route>
              <Redirect to="/market" />
            </Switch>
          </ScrollToTop>
        </>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
}

export default App;
