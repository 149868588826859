const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  displayName: "",
  bio: "",
  headline: "",
  userType: "test",
  mainCategory: "",
  otherCats: "",
  avatar: null,
  acctReady: false,
  acctId: null,
  aPrice: 1499,
  mPrice: 14999,
  subs: [],
};

export const userSlice = (set) => ({
  ...initialState,
  updateFirstName: (firstName) => set(() => ({ firstName })),
  updateLastName: (lastName) => set(() => ({ lastName })),
  updateBio: (bio) => set(() => ({ bio })),
  updateHeadline: (headline) => set(() => ({ headline })),
  updateAvatar: (avatar) => set(() => ({ avatar })),
  updateDisplayName: (displayName) => set(() => ({ displayName })),
  updateUserType: (userType) => set(() => ({ userType })),
  updateMainCat: (mainCategory) => set(() => ({ mainCategory })),
  updateOtherCats: (otherCats) => set(() => ({ otherCats })),
  updateAcctId: (acctId) => set(() => ({ acctId })),
  updateAcctStatus: (acctReady) => set(() => ({ acctReady })),
  updatePrices: ({ mPrice, aPrice }) => set(() => ({ mPrice, aPrice })),
  updateSubs: (subs) => set(() => ({ subs })),
});
