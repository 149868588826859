import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

function Thumbnail({
  aspectRatio = 0.25,
  height = 120,
  width = 80,
  opaque = false,
  vidUploading = false,
  select,
  url,
  loading: forceLoading = false,
}) {
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);
  const rawAdjWidth = (aspectRatio && height * aspectRatio) || width;

  const adjWidth = Math.min(rawAdjWidth, 140);
  const adjHeight = aspectRatio
    ? adjWidth * aspectRatio
    : Math.min(height, 140);

  const loadImg = (param) => {
    setLoading(false);
  };

  // useEffect(() => {
  //   console.log("URL USE EFFECT INVOKED----", url);
  // }, [url]);

  if (vidUploading)
    return (
      <div className="thumbnail">
        <ContentLoader
          speed={2}
          width={adjWidth}
          height={height}
          viewBox={`0 0 ${adjWidth} ${height}`}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" width={height} height={height} />
          <text x="10" y="10" className="heavy">
            test
          </text>
        </ContentLoader>
      </div>
    );

  return (
    <div className="thumbnail">
      <figure>
        <img
          src={url}
          className="thumbnail"
          data-selected={!opaque}
          onClick={() => select(url)}
          height={adjHeight}
          width={adjWidth}
          style={{
            display: loading || err || forceLoading ? "none" : "block",
            opacity: opaque ? 0.5 : 1,
            objectFit: "cover",
          }}
          onLoad={(param) => loadImg(param)}
          onError={(err) => {
            setErr(true);
          }}
          alt="Test"
        ></img>
      </figure>
      {(forceLoading || (loading && !err)) && (
        <div>
          <ContentLoader
            speed={2}
            width={adjWidth}
            height={height}
            viewBox={`0 0 ${adjWidth} ${height}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" width={adjWidth} height={height} />
            <text x="10" y="10" className="heavy">
              test
            </text>
          </ContentLoader>
        </div>
      )}
      {loading && err && !forceLoading && <div>No Img</div>}
    </div>
  );
}

export default Thumbnail;
