import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useBoundStore } from "../state/store";
import Avatar from "../components/Avatar";
import Thumbnail from "../components/Thumbnail";
import UploadModal from "../components/UploadModal";
import PageLoader from "../components/PageLoader";
import Button from "../components/Button";
import { getUserVids, getOwnVids } from "../utility";
import { generateThumbnailUrl } from "../utility/videoHelpers";

const months = {
  0: "Jan",
  1: "Feb",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "Aug",
  8: "Sept",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

const publishedStatuses = ["publish", "schedule"];
const unpublishedStatuses = ["draft", "inProgress", "uploading"];

const truncate = (input, max = 80) =>
  input?.length > max ? `${input.substring(0, max)}...` : input;

let pollVids = null;

function CreatorDash() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  // const [vids, updateVids] = useState([]);
  const [loading, updateLoading] = useState(true);
  const now = new Date().toISOString();

  /** Zustand state */
  const editModal = useBoundStore((state) => state.editModal);
  const avatar = useBoundStore((state) => state.avatar);
  const firstName = useBoundStore((state) => state.firstName);
  const vids = useBoundStore((state) => state.vids);

  /** Zustand methods for editing a video */
  const setVidId = useBoundStore((state) => state.setVidId);
  const updateDesc = useBoundStore((state) => state.updateDesc);
  const updateTitle = useBoundStore((state) => state.updateTitle);
  const updateReleaseOpt = useBoundStore((state) => state.updateReleaseOpt);
  const updateReleaseDate = useBoundStore((state) => state.updateReleaseDate);
  const updateThumbTs = useBoundStore((state) => state.updateThumbTs);
  const setVidMetadata = useBoundStore((state) => state.setVidMetadata);
  const setEditModal = useBoundStore((state) => state.setEditModal);
  const setVidDur = useBoundStore((state) => state.setVidDur);

  /** Other Zustand methods */
  const updateVids = useBoundStore((state) => state.updateVids);

  const editVideo = (row) => {
    setVidId(row.id);
    updateDesc(row.desc);
    updateTitle(row.title);
    updateReleaseOpt(row.status);
    updateReleaseDate(row.relDate);
    updateThumbTs(row.thumbTs);
    setVidMetadata({ width: row.w, height: row.h });
    setVidDur(row.dur);
    setEditModal(true);
  };

  const updateVideos = (editedVid) => {
    const newVids = vids.map((v) => {
      if (v.id === editedVid.cfId) {
        v.desc = editedVid.desc;
        v.title = editedVid.title;
        v.status = editedVid.releaseOpt;
        v.relDate = editedVid.releaseDate;
        if (editedVid.releaseOpt === "publish") v.vis = true;
        if (editedVid.releaseOpt === "draft") v.vis = false;
      }
      return v;
    });
    updateVids(newVids);
    setEditModal(false);
  };

  const columns = [
    {
      name: "Video",
      selector: (r) => r.thumb,
      grow: 3,
      cell: (r) => {
        const url =
          (r.thumb && `${r.thumb}`) ||
          generateThumbnailUrl(r.id, r.thumbTs, 240, r.dur);
        const loading = r.uState === "inProgress";

        return (
          <div className="thumbnail-with-desc" onClick={() => editVideo(r)}>
            <Thumbnail
              loading={loading}
              url={url}
              height={80}
              width={40}
              selectedIndex={1}
              index={1}
              aspectRatio={1}
            />
            <div className="thumbnail-info">
              <span className="thumbnail-title">{r.title}</span>
              <div className="thumbnail-desc">
                {truncate(r.desc) || "Add Description"}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: "Visibility Status",
      style: { alignItems: "start", marginTop: "12px" },
      grow: 1,
      selector: (r) => {
        if (!r.vis) return `Private`;
        const scheduled = r.status === "schedule" && r.relDate > now;
        if (scheduled) return `Scheduled`;
        const published = r.relDate < now && r.status !== "draft";

        if (published) return `Published`;
        return `Private`;
      },
      sortable: true,
      cell: (r) => {
        const visText = r.vis ? "Published" : "Private";
        const icon = r.vis ? (
          <FiEye className="vis-icon" />
        ) : (
          <FiEyeOff className="vis-icon" />
        );
        return (
          <div className="vis-com" onClick={() => editVideo(r)}>
            {icon}
            <div>{visText}</div>
          </div>
        );
      },
    },
    {
      name: "Date",
      style: { alignItems: "start", marginTop: "12px" },
      grow: 2,
      selector: (r) => {
        if (r.uState === "inProgress") return "Upload in progress";
        const date = moment(r.relDate);

        const scheduled = r.status === "schedule" && r.relDate > now;
        if (scheduled)
          return `Scheduled for release ${date.format("MMM Do, h:mm a")} `;

        if (!r.vis) return `Uploaded on ${date.format("MMM Do, h:mm a")}`;
        const published = r.relDate < now && r.status !== "draft";

        if (published) return `Published ${date.format("MMM Do, h:mm a")}`;
        return `Not Scheduled`;
      },
      sortable: true,
      sortFunction: (a, b) => {
        if (
          publishedStatuses.includes(a.status) &&
          !publishedStatuses.includes(b.status)
        )
          return -1;
        if (
          publishedStatuses.includes(b.status) &&
          !publishedStatuses.includes(a.status)
        )
          return 1;
        if (b.relDate && (!a.relDate || a.relDate === "none")) return 1;
        if (a.relDate && (!b.relDate || b.relDate === "none")) return -1;
        if (b.relDate && (!a.relDate || a.relDate === "none")) return 1;
        if (a.relDate > b.relDate) return -1;
        if (b.relDate > a.relDate) return 1;
        return -1;
      },
    },
    {
      name: "Views",
      grow: 1,
      style: { alignItems: "start", marginTop: "12px" },
      selector: (r) => r.views || 100,
    },
    {
      name: "",
      grow: 1,
      style: { alignItems: "start", marginTop: "12px" },
      selector: (r) => "Edit Video",
      cell: (r) => (
        <Button color={"var(--cta-color)"} onClick={() => editVideo(r)} />
      ),
    },
  ];

  /** Polling should no longer be neccessary, but can always be turned on */

  // const initPolling = () => {
  //   if (pollVids) clearInterval(pollVids);
  //   pollVids = setInterval(async () => {
  //     const allVids = await getUserVids(user?.email);

  //     if (allVids && allVids.length) updateVids(allVids);
  //   }, 5000);
  // };

  const stopPolling = () => {
    if (pollVids) clearInterval(pollVids);
    pollVids = null;
  };

  useEffect(() => {
    async function grabVids() {
      updateLoading(true);
      const allVids = await getOwnVids();
      updateVids(allVids);
      updateLoading(false);
    }
    updateLoading(false);
    if (user) grabVids();

    return stopPolling;
  }, [user]);

  if (loading || isLoading || !isAuthenticated) return <PageLoader />;

  return (
    <div className="flex-container">
      <Avatar height={240} url={avatar} />
      <div className="main-title">{`${firstName}'s Workouts`}</div>

      {vids && vids.length ? (
        <>
          <DataTable
            columns={columns}
            data={vids}
            highlightOnHover={true}
            pointerOnHover={true}
            onRowClicked={editVideo}
          />
          <UploadModal
            active={editModal}
            close={() => setEditModal(false)}
            editing={true}
            submitCB={updateVideos}
          />
        </>
      ) : (
        <div>No videos found, Create a video here</div>
      )}
    </div>
  );
}

export default withAuthenticationRequired(CreatorDash);
