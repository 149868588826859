import React from "react";

// aspect ratio is width / height
const aspectRatio = 1280 / 960;

function Card({
  smallUri = "https://bulma.io/images/placeholders/96x96.png",
  mainUri = "https://bulma.io/images/placeholders/1280x960.png",
  desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris. <a>@bulmaio</a>.<a href="#">#css</a>{" "}<a href="#">#responsive</a>',
  onClick,
  mainTag = "barre",
  otherTags = "yoga,meditation,dance",
  title = "Bridget Norman",
  subtitle = "Subtitle",
  price = 1499,
}) {
  const tags = otherTags?.split(",");

  if (mainTag && !otherTags.includes(mainTag)) tags.unshift(mainTag);

  return (
    <div className="box search-card-container clickable" onClick={onClick}>
      <img className="search-card-img" src={mainUri} width={320} height={240} />
      <div className="search-card-content">
        <div className="content-box-title">{title}</div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {tags?.map &&
            tags.map((t) => {
              return (
                <span
                  className="tag"
                  data-type={t}
                  key={t}
                  style={{ marginRight: 8 }}
                >
                  {t}
                </span>
              );
            })}
        </div>
        <div className="search-subtitle">{subtitle}</div>
        <div>{desc}</div>
      </div>
      <div className="search-price">{`$${price / 100} per month`}</div>
    </div>
  );
}

export default Card;
