/** The focus of this slice is anything to do with creator's video management */

const initialState = {
  vids: [],
};

export const creatorSlice = (set) => ({
  ...initialState,
  updateVids: (vids) => set(() => ({ vids })),
  editVid: (newVid) =>
    set((state) => {
      const oldVids = state.vids;
      console.log("OLD VIDS", oldVids);

      const newVids = oldVids.map((vid) => {
        if (vid?.id === newVid?.id) {
          return newVid;
        }
        return vid;
      });

      console.log("newVids", newVids);

      return { ...state, vids: newVids };
    }),
});
